.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.login-container {
  min-height: 100vh;
  width: 100%;
  display:block !important;
 
  display: flex !important;
  flex: 1 !important;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.dashboard-container {
  min-height: 100vh;
  width: 100%;
  display:block !important;
  background-image: linear-gradient(to right top, #949597, #aeaeb0, #c8c9ca, #e3e3e4, #ffffff);
  padding: 10px !important;
}
.exam-container {
  height: 100% !important;
  width: 100%;
  display:block !important;
 text-align: center !important;
}
.txt{
  text-align: center!important;
  font-size: 30px !important;
  font-weight: 300 !important;
  margin-bottom: 50px !important;
}
.btn{
  background-color: black !important;
  color: white !important;
  width: 100% !important;
  border-color: black !important;
}
.input-element{
  margin-top: 10px !important;
  border: 2px solid #3D89F7  !important;
}
.radio-text{
  margin-top: -10px !important;
}
.user-icon{
  height: 70px !important;
  width: 70px !important;
  border-radius: 50% !important;
  background-color: #d3d3d4 !important;
  color: black !important;
  border: 1px solid black !important;
  text-align: center !important;
  padding: 10px !important;
  margin: 5px !important;
}
.delete-icon{
  height:50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  background-color: rgb(203, 9, 9)!important;
  border-color: rgb(203, 9, 9);
  border: none !important;
  text-align: center !important;
  padding: 10px !important;
  margin: 5px !important;
}
.txt-style{
  color: black !important;
  font-size: 40px !important;
}
.card-style{
  
  height: 60vh !important;
}
.login-card-style{
  background-color: #f2f2f2 !important;
  height: 50% !important;
  width: 25% !important;
}
.register-card{
  background-color: #f2f2f2!important;
  width: 40% !important;
}
.exam-card{
  height: 100% !important;
  margin-top: 10vh !important;
  width: 40% !important;
  border: 1px solid gray !important;
  padding: 10px !important;
  text-align: left !important;
  background-color: #f2f2f2!important;
}
.navbar-nav  a, .navbar-brand  {
  color:white !important;
  text-decoration: none !important;
}
.card-style{
  margin-top: 20px !important;
  text-align: center!important;
  
}
.login-txt{
  text-decoration:none !important; 
}
.sl-no{
  font-size: 25px !important;
  font-weight: 600 !important;
}
.userlist-card{
  background-color: #f4f4f4 !important;
  border:0;
  padding:10px;
  padding-left: 20px;
}
.answer-table{
  margin-top: 10px !important;
}
.t-width{
  width: 40px !important;
}
.answer-container{
  width: 80% !important;
  background-color: #f2f2f2 !important;
  margin-top: 20px !important;
}
.footer-div{
  background-color: black !important;
  color: white !important;
  text-align: center !important;
  width: 100% !important;
  height: 30vh !important;
  margin-top: 70px !important;
}
.p-style-black {
  color: white !important;
  text-decoration: none !important;
}
.link-style{
  text-decoration: none !important;
  color: white !important;
  font-weight: bold !important;
}
.logout-icon{
 color: white !important;

}
.t-body{
  width: 100% !important;
  height: 90vh !important;
  overflow: scroll !important;
}
.useraddModal {
  position: absolute !important;
  left: 22% !important;
  width: 60% !important;
}
.deleteModal {
  position: absolute;
}
.cancelBtn {
  background-color: white !important;
  color: #65676D !important;
  border: 0px !important;
}
.orangeColor {
  color: green;
}
.dropdownStyle{
  width:160px !important;
  float:right !important
}
.rad , .aler {
  cursor: pointer !important;
}
/* MeDia query ------------------------------------------ */

/* For Desktop View */
@media screen and (min-width: 1024px) {
  .login-card-style {
    width: 25% !important;

  }
  .exam-card {
    width: 40% !important;
  }
  .register-card {
    width: 40% !important;
  }
}

/* For Tablet View */
@media screen and (min-device-width: 768px) 
  and (max-device-width: 1024px) {
    .login-card-style {
      width: 25% !important;
  
    }
    .exam-card {
      width: 40% !important;
    }
    .register-card {
      width: 30% !important;
    }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) 
  and (orientation: portrait) {
    .login-card-style {
      width: 99% !important;
  
    }
    .exam-card {
      width: 99% !important;
    }
    .register-card {
      width: 99% !important;
    }
}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) 
  and (orientation: landscape) {
    .login-card-style {
      width: 25% !important;
  
    }
    .exam-card {
      width: 40% !important;
    }
    .register-card {
      width: 30% !important;
    }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen
  and (max-device-width: 640px) {
    .login-card-style {
      width: 99% !important;
  
    }
    .exam-card {
      width: 99% !important;
    }
    .register-card {
      width: 99% !important;
    }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .login-card-style {
      width: 99% !important;
  
    }
    .exam-card {
      width: 99% !important;
    }
    .register-card {
      width: 99% !important;
    }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) 
  and (device-width: 320px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .login-card-style {
      width: 99% !important;
  
    }
    .exam-card {
      width: 99% !important;
    }
    .register-card {
      width: 99% !important;
    }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) 
  and (min-device-width: 375px) 
  and (-webkit-min-device-pixel-ratio: 3) {
    .login-card-style {
      width: 99% !important;
  
    }
    .exam-card {
      width: 99% !important;
    }
    .register-card {
      width: 99% !important;
    }
};

/* --------------------------------------------------------*/